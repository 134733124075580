import React, { useEffect, useState } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import ProductDetails from "../ProductDetails";
import { LiaAmazon } from "react-icons/lia";
import Rating from "@mui/material/Rating";
import { FaCopy } from "react-icons/fa";
import { IoCubeOutline } from "react-icons/io5";
import { BsFillBoxFill } from "react-icons/bs";
import { HiMiniBars3 } from "react-icons/hi2";
import { LuMonitor } from "react-icons/lu";
import { FaGoogle } from "react-icons/fa";
import Tooltip from "../SettingComponents/Tooltip";
import TooltipSimple from "../SettingComponents/TooltipSimple";
import {
  cmToInches,
  formatLength,
  formatNumber,
  formatWeight,
} from "../../utils/formatData";
import { toast } from "sonner";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useSelector } from "react-redux";
import { FaExclamation } from "react-icons/fa6";
import { GoDotFill } from "react-icons/go";

const Overview = ({
  asin,
  eanList,
  data,
  basicInfo,
  amazonFees,
  salesData,
  loading,
  rating,
  settingsData,
  amazonLink,
  dimensions,
  description,
  referralFeePercent,
  alerts,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const [scoreValue, setScoreValue] = useState(0);
  const [reasons, setReasons] = useState([]);
  const { scores } = useSelector((state) => state.score);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };
  const getFinalScore = (scores) => {
    // calculate the final score
    const weights = {
      tier1: 0.4, // bbPriceScore, offerScore, salesScore
      tier2: 0.25, // bbPercentageScore, bbSuppressionScore
      tier3: 0.2, // brandScore, amazonStockScore
      tier4: 0.1, // reviewRatingScore, listingAgeScore
    };
    const tier1Score =
      (scores.bbPriceScore?.score +
        scores.offerScore?.score +
        scores.salesScore?.score) /
      3;
    const tier2Score =
      (scores.bbPercentageScore?.score + scores.bbSuppressionScore?.score) / 2;
    const tier3Score =
      (scores.brandScore?.score + scores.amazonStockScore?.score) / 2;
    const tier4Score =
      (scores.reviewRatingScore?.score + scores.listingAgeScore?.score) / 2;

    const weightedScore =
      tier1Score * weights.tier1 +
      tier2Score * weights.tier2 +
      tier3Score * weights.tier3 +
      tier4Score * weights.tier4;

    return ((weightedScore / 20) * 10).toFixed(1);
  };

  useEffect(() => {
    if (scores) {
      if (reasons.length === 0) {
        Object.values(scores).forEach((s) => {
          if (s && s.reason) { 
            setReasons((prev) => [...prev, { reason: s.reason, alert: s.alert }]);
          }
        });
      }
      let finalScore = getFinalScore(scores);
      setScoreValue(finalScore);
    }
  }, [scores]);
  

  const asinValue = asin ? asin : "-";
  const eanValue = eanList?.length > 0 && eanList[0] ? eanList[0] : "";

  return (
    <React.Fragment>
      <div
        className="flex flex-col w-full border-primary-light bg-primary p-4 mt-4"
        style={{
          border: "0.5px solid #76F99B",
          borderRadius: "24px",
        }}
      >
        <div className="flex items-center">
          <div
            class="inline-block top-20 left-20 gap-0 rounded-t-lg bg-secondary w-36 h-36"
            style={{
              border: "0.5px solid #376C46",
              background: "rgba(118, 249, 155, 0.15)",
              borderRadius: "20px",
            }}
          >
            {!data?.image ? (
              <div
                className="flex items-center justify-center h-36 bg-gray-300 rounded sm:w-96 dark:bg-secondary w-36"
                style={{
                  border: "0.5px solid #376C46",
                  background: "rgba(118, 249, 155, 0.15)",
                  borderRadius: "20px",
                }}
              >
                <svg
                  className="w-10 h-10 text-gray-200 dark:text-[#76F99B]"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 18"
                >
                  <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
                </svg>
              </div>
            ) : (
              <img
                src={data?.image}
                alt="Product 1"
                class="m-auto rounded-t-lg w-36 h-36 aspect-square"
                style={{
                  borderRadius: "20px",
                }}
              />
            )}
          </div>

          <div className="text-primary-light text-left w-[50%] px-3">
            {!loading ? (
              <>
                <div
                  className="text-white font-bold"
                  style={{
                    fontSize: "12px",
                  }}
                >
                  {basicInfo?.brand ? basicInfo?.brand : ""}
                </div>
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                  className="group relative cursor-default"
                >
                  {basicInfo?.productName
                    ? basicInfo.productName.length > 35
                      ? basicInfo.productName.substring(0, 35) + "..."
                      : basicInfo.productName
                    : "--"}
                  <TooltipSimple
                    text={basicInfo?.productName}
                    width={"extra"}
                  />
                </div>
                <div
                  className="text-white font-bold"
                  style={{
                    fontSize: "12px",
                  }}
                >
                  {basicInfo?.category ? basicInfo?.category : ""}
                </div>
                <div className="flex items-end mt-1">
                  <Rating
                    name="read-only"
                    value={Number(rating?.rating)}
                    precision={0.1}
                    readOnly
                    style={{
                      fontSize: "13px",
                      color: "#76F99B",
                    }}
                  />
                  <span
                    className="text-white ml-1"
                    style={{
                      fontSize: "10px",
                    }}
                  >
                    {rating?.ratings_total
                      ? formatNumber(Number(rating.ratings_total))
                      : 0}
                  </span>
                </div>
                <div
                  className="mt-2"
                  style={{
                    fontSize: "0.7rem",
                  }}
                >
                  <div className="text-white">
                    ASIN: {asinValue}
                    <span className="cursor-pointer text-primary-light">
                      <FaCopy
                        className="ml-2"
                        style={{
                          fontSize: "0.7rem",
                          display: "inline",
                        }}
                        onClick={() => {
                          navigator.clipboard.writeText(asinValue);
                          toast.success("ASIN copied to clipboard");
                        }}
                      />
                    </span>
                  </div>
                  {eanValue && (
                    <div className="text-white text-left">
                      EAN: {eanValue}
                      <span className="cursor-pointer text-primary-light">
                        <FaCopy
                          className="ml-2"
                          style={{
                            fontSize: "0.7rem",
                            display: "inline",
                          }}
                          onClick={() => {
                            navigator.clipboard.writeText(eanValue);
                            toast.success("EAN copied to clipboard");
                          }}
                        />
                      </span>
                    </div>
                  )}
                </div>
                <div className="flex justify-between w-[80%] mt-2">
                  <div className="flex items-center group relative bg-secondary rounded-md px-2 py-1 text-primary-light hover:bg-primary-light hover:text-secondary transition duration-300">
                    <BsFillBoxFill className="w-3.5 h-3.5" />
                    <TooltipSimple
                      width={"wide"}
                      text={`<strong>Product Dimensions :</strong><br/>
                                                        Width: ${formatLength(
                                                          dimensions?.width
                                                        )} (${cmToInches(
                        dimensions?.width
                      )})<br/>
                                                        Height: ${formatLength(
                                                          dimensions?.height
                                                        )} (${cmToInches(
                        dimensions?.height
                      )})<br/>
                                                        Length: ${formatLength(
                                                          dimensions?.length
                                                        )} (${cmToInches(
                        dimensions?.length
                      )})<br/>
                                                        Weight: ${formatWeight(
                                                          dimensions?.weight
                                                        )}`}
                    />
                  </div>
                  <div className="flex items-center group relative bg-secondary rounded-md px-2 py-1 text-primary-light hover:bg-primary-light hover:text-secondary transition duration-300">
                    <HiMiniBars3 className="w-3.5 h-3.5" />
                    <TooltipSimple
                      text={description || "No description"}
                      width={"extra"}
                    />
                  </div>
                  <div
                    className="flex items-center group relative cursor-pointer bg-secondary rounded-md px-2 py-1 text-primary-light hover:bg-primary-light hover:text-secondary transition duration-300"
                    onClick={() => {
                      window.open(amazonLink, "_blank");
                    }}
                  >
                    <LiaAmazon className="w-3.5 h-3.5" />
                    <TooltipSimple text="View Product on Amazon" />
                  </div>
                  <div
                    className="flex items-center group relative cursor-pointer bg-secondary rounded-md px-2 py-1 text-primary-light hover:bg-primary-light hover:text-secondary transition duration-300"
                    onClick={() => {
                      window.open(
                        `https://www.google.com/search?q=${encodeURIComponent(
                          basicInfo?.productName
                        )}`,
                        "_blank"
                      );
                    }}
                  >
                    <FaGoogle className="w-3.5 h-3.5" />
                    <TooltipSimple text="Search Product title on Google" />
                  </div>
                  {/* <div className="flex items-center group relative bg-secondary rounded-md px-2 py-1 text-primary-light hover:bg-primary-light hover:text-secondary transition duration-300">
                    <LuMonitor className="w-3 h-3" />
                  </div> */}
                </div>
              </>
            ) : (
              <div
                role="status"
                className="animate-pulse md:space-y-0 md:space-x-8 md:flex md:items-center flex items-center"
              >
                <div className="w-full">
                  <div className="h-2.5 bg-gray-400 rounded-full dark:bg-secondary max-w-[360px] mb-4"></div>
                  <div className="h-2 bg-gray-200 rounded-full dark:bg-secondary max-w-[200px] mb-2.5"></div>
                  <div className="h-2 bg-gray-200 rounded-full dark:bg-secondary mb-2.5"></div>
                  <div className="h-2 bg-gray-200 rounded-full dark:bg-secondary max-w-[100px] mb-2.5"></div>
                  <div className="h-2 bg-gray-200 rounded-full dark:bg-secondary max-w-[100px] mb-2.5"></div>
                  <div className="h-2 bg-gray-200 rounded-full dark:bg-secondary max-w-[100px]"></div>
                </div>
                <span className="sr-only">Loading...</span>
              </div>
            )}
          </div>
          <div className="w-[28%] p-3 rounded-lg bg-secondary flex flex-col gap-y-3">
            <h2 className="mx-auto text-primary-light font-semibold">
              Scan Score
            </h2>
            <div className="w-22 h-22 group relative cursor-default">
              <CircularProgressbarWithChildren
                value={scoreValue}
                maxValue={10}
                background={true}
                styles={{
                  // Customize the root svg element
                  root: {},
                  // Customize the path, i.e. the "completed progress"
                  path: {
                    // Path color
                    stroke: `${
                      scoreValue >= 7
                        ? "#76F99B"
                        : scoreValue < 7 && scoreValue >= 4
                        ? "#EAAA08"
                        : "#E74B4B"
                    }`,
                    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                    strokeLinecap: "butt",
                    // Customize transition animation
                    transition: "stroke-dashoffset 0.5s ease 0s",
                    transformOrigin: "center center",
                  },
                  // Customize the circle behind the path, i.e. the "total progress"
                  trail: {
                    // Trail color
                    stroke: "#1D4331",
                    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                    strokeLinecap: "butt",
                    // Rotate the trail
                    transform: "rotate(0.25turn)",
                    transformOrigin: "center center",
                  },
                  background: {
                    fill: "#376C46",
                    boxShadow:
                      "rgba(255, 255, 255, 0.3) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.5) 0px 30px 60px -30px",
                  },
                }}
              >
                <div className="flex">
                  <p
                    className={`font-semibold ${
                      scoreValue >= 7
                        ? "text-primary-light"
                        : scoreValue < 7 && scoreValue >= 4
                        ? "text-[#EAAA08]"
                        : scoreValue === 0
                        ? "text-white"
                        : "text-[#E74B4B]"
                    } text-xl`}
                  >
                    {scoreValue}
                  </p>
                  <p className="pt-1 font-semibold text-white text-2xl">/</p>
                  <p className="pt-3 text-sm text-white">10</p>
                </div>
              </CircularProgressbarWithChildren>
              {reasons?.length > 0 && (
                <TooltipSimple
                  width={"extra"}
                  position={"left-[10%]"}
                  CustomJsx={() => (
                    <div className="flex flex-col gap-y-1 text-left justify-start">
                      {reasons.map((r, i) => {
                        return (
                          <div
                            key={i}
                            className={`${
                              r?.alert ? "text-yellow-500" : "text-gray-100"
                            } flex gap-x-1`}
                          >
                            {r?.alert ? (
                              <FaExclamation className="w-2 h-3 mt-0.5" />
                            ) : (
                              <GoDotFill className="w-2 h-2 mt-1" />
                            )}
                            <p className="w-[95%]">{r?.reason}</p>
                          </div>
                        );
                      })}
                    </div>
                  )}
                />
              )}
            </div>
          </div>
        </div>
        <div className="mt-4">
          <div className="">
            <div
              className="flex cursor-pointer items-center text-white"
              onClick={toggleAccordion}
            >
              <div
                className="mr-1 text-white"
                style={{
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                Overview
              </div>
              <div>{/* {isOpen ? <FaAngleUp /> : <FaAngleDown />} */}</div>
            </div>
            {isOpen && (
              <ProductDetails
                loading={loading}
                data={data}
                amazonFees={amazonFees}
                salesData={salesData}
                settingsData={settingsData}
                referralFeePercent={referralFeePercent}
                alerts={alerts}
                asin={asinValue}
                basicInfo={basicInfo}
              />
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Overview;
