import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { store } from "./redux/store";
import { Provider } from "react-redux";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import BugsnagPerformance from "@bugsnag/browser-performance";

const bugsnag_api = process.env.REACT_APP_BUGSNAG_API || ' '

Bugsnag.start({
  apiKey: bugsnag_api,
  plugins: [new BugsnagPluginReact()],
});
BugsnagPerformance.start({ apiKey: bugsnag_api });

const root = ReactDOM.createRoot(document.getElementById("root"));
const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

root.render(
  <>
  <ErrorBoundary>
      <GoogleOAuthProvider clientId="61743671997-c1s3ep3msf8v93q4i3f5hli7ttoem8t1.apps.googleusercontent.com">
        <Provider store={store}>
          <App />
        </Provider>
      </GoogleOAuthProvider>
  </ErrorBoundary>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
